import React from "react";

const About = () => {
	return (
		<div className="w-[86.9%] min-h-[474.6px] max-w-[1358px] text-left mt-[60px] mx-auto py-[40px] space-y-5">
			<h1 className="font-[700] text-[#333] text-[30px]">You Want to Reach Millions of People.  &nbsp; Let My Storytelling Take You There.</h1>
			<p className="text-[17px] leading-[1.7em] font-[400]">Hello! I’m Dhara. I’m a writer who is equal parts left and right-brained; I’m deeply intuitive yet logical. With each brand and client I come across, I ask only the necessary questions to craft writing that marries your brand voice with words your ideal client can understand.&nbsp;</p>
			<p className="text-[17px] leading-[1.7em] font-[400]">I have an uncanny ability to connect the dots within a short time frame and build solid relationships on and offline.... I often get the feedback “How In a Short Time I’m Able to Understand Someone’s Brand Well.”</p>
			<p className="text-[17px] leading-[1.7em] font-[400]">Because more than a writer, I’m a team player who cares about your success as if it’s her own.</p>
			<h1 className="font-[700] text-[#333] text-[30px]">
				How Can I Help You?
			</h1>
			<p className="text-[17px] leading-[1.7em] font-[400]">I can help your team with an assortment of freelance writing tasks:</p>
			<ul className="text-[17px] leading-[1.7em] font-[400] pl-5">
				<li className="list-inside list-disc">B2C/B2B Copywriting: Email Marketing Sales Pages, Funnels</li>
				<li className="list-inside list-disc">SEO-Optimized Financial Writing</li>
				<li className="list-inside list-disc">SEO-Optimized Technology Writing</li>
				<li className="list-inside list-disc">Press Releases</li>
				<li className="list-inside list-disc">Website Copy: Brand Stories, About Me, Founder Stories, Mission Statements, and More!</li>
				<li className="list-inside list-disc">Ghostwriting</li>
			</ul>
			<p className="text-[17px] leading-[1.7em] font-[400]">I can also host workshops for your team on a variety of topics:</p>
			<ul className="text-[17px] leading-[1.7em] font-[400] pl-5">
				<li className="list-inside list-disc">Discovering Your Purpose</li>
				<li className="list-inside list-disc">Uncovering Your Unique Brand Identity</li>
				<li className="list-inside list-disc">How to Tell Your Brand Story</li>
			</ul>
			<h1 className="font-[700] text-[#333] text-[30px]">
				How Do I Do It?
			</h1>
			<ul className="text-[17px] leading-[1.7em] font-[400]">
				<li className="list-inside list-disc">I have a proprietary interview framework that I’ve crafted based on 150+ client interviews. This allows me to understand your brand’s distinct vision, dream impact, and origin story. </li>
				<li className="list-inside list-disc">I interview all the stakeholders if you represent a company or organization.</li>
				<li className="list-inside list-disc">You can learn in-depth about <a href="https://storybydhara.com/testimonial" target="_blank" className="cursor-pointer text-blue-700 hover:underline">the results of this process here.</a></li>
			</ul>
			<a href="https://calendly.com/storiestosales/consulting" target="_blank" className="bg-[#00bcd4] flex w-fit text-white px-[24px] py-[15px] rounded-[4px] hover:bg-[#008fa1] font-[500] text-[17px]" style={{ boxShadow: 'inset 0 -4px 0 0 rgba(0,0,0,.2)', transition: 'all .3s ease-in-out', border: "1px solid rgba(0,0,0,.2)" }}><span>Consult <i className="fa fa-arrow-right"></i></span></a>
		</div>
	)
}

export default About