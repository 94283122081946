export const articles = [
  {
    name: "Best retirement plans of 2024",
    index: "1",
    pdf_path: "/articles/1.pdf",
    preview: "/previews/1.cover.png",
  },
  {
    name: "How to retire early - 5 steps to early retirement",
    index: "2",
    pdf_path: "/articles/2.pdf",
    preview: "/previews/2.cover.png",
  },
  {
    name: "Best cash advance apps for gig workers",
    index: "3",
    pdf_path: "/articles/3.pdf",
    preview: "/previews/3.cover.png",
  },
  {
    name: "Beagle review 2024",
    index: "4",
    pdf_path: "/articles/4.pdf",
    preview: "/previews/4.cover.png",
  },
  {
    name: "How to get a $400,000 business loan",
    index: "5",
    pdf_path: "/articles/5.pdf",
    preview: "/previews/5.cover.png",
  },
  {
    name: "How to get a $200,000 business loan",
    index: "6",
    pdf_path: "/articles/6.pdf",
    preview: "/previews/6.cover.png",
  },
  {
    name: "How to get a $150,000 business loan",
    index: "7",
    pdf_path: "/articles/7.pdf",
    preview: "/previews/7.cover.png",
  },
  {
    name: "How to get a $60,000 business loan",
    index: "8",
    pdf_path: "/articles/8.pdf",
    preview: "/previews/8.cover.png",
  },
  {
    name: "What is a credit builder loan",
    index: "9",
    pdf_path: "/articles/9.pdf",
    preview: "/previews/9.cover.png",
  },
  {
    name: "What credit score is needed for a personal loan",
    index: "10",
    pdf_path: "/articles/10.pdf",
    preview: "/previews/10.cover.png",
  },
  {
    name: "How long does it take to build credit",
    index: "11",
    pdf_path: "/articles/11.pdf",
    preview: "/previews/11.cover.png",
  },
  {
    name: "What's a bad credit score",
    index: "12",
    pdf_path: "/articles/12.pdf",
    preview: "/previews/12.cover.png",
  },
  {
    name: "What is a good credit score",
    index: "13",
    pdf_path: "/articles/13.pdf",
    preview: "/previews/13.cover.png",
  },
  {
    name: "5 small business stories that'll make you rethink taking out a loan",
    index: "14",
    pdf_path: "/articles/14.pdf",
    preview: "/previews/14.cover.png",
  },
  {
    name: "5 cash advance apps that work with albert",
    index: "15",
    pdf_path: "/articles/15.pdf",
    preview: "/previews/15.cover.png",
  },
  {
    name: "Loans like viva finance",
    index: "16",
    pdf_path: "/articles/16.pdf",
    preview: "/previews/16.cover.png",
  },
  {
    name: "How much money should i have saved by 30",
    index: "17",
    pdf_path: "/articles/17.pdf",
    preview: "/previews/17.cover.png",
  },
  {
    name: "4 cash advance apps that work with netspend",
    index: "18",
    pdf_path: "/articles/18.pdf",
    preview: "/previews/18.cover.png",
  },
  {
    name: "Fundbox vs. american epress business line of credit: which is better?",
    index: "19",
    pdf_path: "/articles/19.pdf",
    preview: "/previews/19.cover.png",
  },
  {
    name: "OnDeck vs. american epress business line of credit: which is better?",
    index: "20",
    pdf_path: "/articles/20.pdf",
    preview: "/previews/20.cover.png",
  },
  {
    name: "Bluevine vs. fundbox: which is better?",
    index: "21",
    pdf_path: "/articles/21.pdf",
    preview: "/previews/21.cover.png",
  },
  {
    name: "5 cash advance apps that work with venmo",
    index: "22",
    pdf_path: "/articles/22.pdf",
    preview: "/previews/22.cover.png",
  },
  {
    name: "7 cash advance apps that work with ADP",
    index: "23",
    pdf_path: "/articles/23.pdf",
    preview: "/previews/23.cover.png",
  },
  {
    name: "5 cash advance apps that work with paypal",
    index: "24",
    pdf_path: "/articles/24.pdf",
    preview: "/previews/24.cover.png",
  },
  {
    name: "fundera vs. lendio: which is better?",
    index: "25",
    pdf_path: "/articles/25.pdf",
    preview: "/previews/25.cover.png",
  },
  {
    name: "upgrade vs. upstart: which is better?",
    index: "26",
    pdf_path: "/articles/26.pdf",
    preview: "/previews/26.cover.png",
  },
  {
    name: "lightstream vs. sofi: which is better?",
    index: "27",
    pdf_path: "/articles/27.pdf",
    preview: "/previews/27.cover.png",
  },
  {
    name: "Best egg vs. upstart: which is better?",
    index: "28",
    pdf_path: "/articles/28.pdf",
    preview: "/previews/28.cover.png",
  },
  {
    name: "upstart vs. sofi: which is better?",
    index: "29",
    pdf_path: "/articles/29.pdf",
    preview: "/previews/29.cover.png",
  },
  {
    name: "sofi vs. lendingclub: which is better?",
    index: "30",
    pdf_path: "/articles/30.pdf",
    preview: "/previews/30.cover.png",
  },
  {
    name: "5 loans like fig loans",
    index: "31",
    pdf_path: "/articles/31.pdf",
    preview: "/previews/31.cover.png",
  },
  {
    name: "5 best stock picking services of 2024",
    index: "32",
    pdf_path: "/articles/32.pdf",
    preview: "/previews/32.cover.png",
  },
  {
    name: "how to buy vinfast stock",
    index: "33",
    pdf_path: "/articles/33.pdf",
    preview: "/previews/33.cover.png",
  },
  {
    name: "how to buy discord stock when it goes public",
    index: "34",
    pdf_path: "/articles/34.pdf",
    preview: "/previews/34.cover.png",
  },
  {
    name: "how to read stock charts: a beginner's guide",
    index: "35",
    pdf_path: "/articles/35.pdf",
    preview: "/previews/35.cover.png",
  },
  {
    name: "how to buy databricks stock when it goes public",
    index: "36",
    pdf_path: "/articles/36.pdf",
    preview: "/previews/36.cover.png",
  },
  {
    name: "how to buy triller (ILLR) stock when it goes public",
    index: "37",
    pdf_path: "/articles/37.pdf",
    preview: "/previews/37.cover.png",
  },
  {
    name: "How to buy stripe stock when it goes public",
    index: "38",
    pdf_path: "/articles/38.pdf",
    preview: "/previews/38.cover.png",
  },
  {
    name: "How to buy Airbnb stock",
    index: "39",
    pdf_path: "/articles/39.pdf",
    preview: "/previews/39.cover.png",
  },
  {
    name: "what is after-hours trading and how does it work",
    index: "40",
    pdf_path: "/articles/40.pdf",
    preview: "/previews/40.cover.png",
  },
  {
    name: "what is day trading and how does it work",
    index: "41",
    pdf_path: "/articles/41.pdf",
    preview: "/previews/41.cover.png",
  },
  {
    name: "how to buy berkshire hathaway stock",
    index: "42",
    pdf_path: "/articles/42.pdf",
    preview: "/previews/42.cover.png",
  },
  {
    name: "how to buy walmart stock",
    index: "43",
    pdf_path: "/articles/43.pdf",
    preview: "/previews/43.cover.png",
  },
  {
    name: "how to buy shopify stock",
    index: "44",
    pdf_path: "/articles/44.pdf",
    preview: "/previews/44.cover.png",
  },
  {
    name: "how to buy target corporation stock",
    index: "45",
    pdf_path: "/articles/45.pdf",
    preview: "/previews/45.cover.png",
  },
  {
    name: "how to buy nike stock",
    index: "46",
    pdf_path: "/articles/46.pdf",
    preview: "/previews/46.cover.png",
  },
  {
    name: "how to buy intel corporation stock",
    index: "47",
    pdf_path: "/articles/47.pdf",
    preview: "/previews/47.cover.png",
  },
  {
    name: "how to buy ford motor company stock",
    index: "48",
    pdf_path: "/articles/48.pdf",
    preview: "/previews/48.cover.png",
  },
  {
    name: "how to buy disney stock",
    index: "49",
    pdf_path: "/articles/49.pdf",
    preview: "/previews/49.cover.png",
  },
  {
    name: "how to buy zoom video communications stock",
    index: "50",
    pdf_path: "/articles/50.pdf",
    preview: "/previews/50.cover.png",
  },
  {
    name: "how to buy alibaba holding stock",
    index: "51",
    pdf_path: "/articles/51.pdf",
    preview: "/previews/51.cover.png",
  },
  {
    name: "flight school loans and how to pay for flight school",
    index: "52",
    pdf_path: "/articles/52.pdf",
    preview: "/previews/52.cover.png",
  },
  {
    name: "optima tax relief review",
    index: "53",
    pdf_path: "/articles/53.pdf",
    preview: "/previews/53.cover.png",
  },
  {
    name: "onDeck vs. prosper: which is better?",
    index: "54",
    pdf_path: "/articles/54.pdf",
    preview: "/previews/54.cover.png",
  },
  {
    name: "loanbuilder vs. onDeck: which is better?",
    index: "55",
    pdf_path: "/articles/55.pdf",
    preview: "/previews/55.cover.png",
  },
  {
    name: "Millennial Inheritances May Look Different Than Expected",
    index: "56",
    pdf_path: "/articles/56.pdf",
    preview: "/previews/56.cover.png",
  },
  {
    name: "Financial Stress Survey: 65% of Americans Say Finances Are Their Biggest Source of Stress",
    index: "57",
    pdf_path: "/articles/57.pdf",
    preview: "/previews/57.cover.png",
  },
  {
    name: "Layoff Anxiety Survey: 7 in 10 Workers Are Bracing for Job Cuts",
    index: "58",
    pdf_path: "/articles/58.pdf",
    preview: "/previews/58.cover.png",
  },
];
