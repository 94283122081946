/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Coach from "assets/images/coach.png";
import Health from "assets/images/health.png";
import Sales from "assets/images/sales.png";
import { copywriting } from "assets/copywriting";

const WebCopy = () => {
  return (
    <div className="mt-[16px] lg:mt-[60px] w-[80%] lg:w-full mx-auto relative">
      <section className="w-[90%] lg:w-[62%] grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-7 mx-auto py-[50px] lg:py-[150px]">
        {copywriting.map((item, index) => (
          <a
            key={index}
            href={item.pdf_path}
            target="_blank"
            rel="noreferer noreferrer"
            download
            className="border-[1px] cursor-pointer relative flex justify-center items-center h-96 p-4 hover:bg-gray-200 transition-colors duration-200 ease-in-out "
          >
            <img
              src={item.image}
              className="opacity-70 w-full h-full object-cover object-center "
              alt="coach"
            />
            <div className="hidden lg:block absolute life-coach">
              {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
              <h1
                className="text-2xl font-[700] text-[#303030] text-center !-tracking-tighter"
                data-text={item.name}
              ></h1>
            </div>
          </a>
        ))}
        <a
          href="https://docs.google.com/document/d/16k1rbFPsuVs4BwLlISVMOGkMKOu-IE7VUlnG7JSv04c/edit?usp=sharing"
          target="_blank"
          className="border-[1px] cursor-pointer relative flex justify-center items-center h-96 p-4 hover:bg-gray-200 transition-colors duration-200 ease-in-out "
        >
          <img
            src={Coach}
            className="opacity-70 w-full h-full object-cover object-center"
            alt=""
          />
          <div className="hidden lg:block absolute life-coach">
            <h1
              className="text-[30px] font-[700] text-[#303030] text-center"
              data-text="Health and Nutrition"
            ></h1>
          </div>
        </a>
        <a
          href="https://docs.google.com/document/d/10HEK-QcDD5G07E7d9OriGBr6IiiCHpME-PtPsajvWrQ/edit?usp=sharing"
          target="_blank"
          className="border-[1px] cursor-pointer relative flex justify-center items-center h-96 p-4 hover:bg-gray-200 transition-colors duration-200 ease-in-out "
        >
          <img
            src={Health}
            className="opacity-70 w-full h-full object-cover object-center"
            alt=""
          />
          <div className="hidden lg:block absolute w-[80%] text-center health">
            <h1
              className="text-[30px] font-[700] text-[#303030]"
              data-text="Life Coach"
            ></h1>
          </div>
        </a>
        <a
          href="https://courses.deepikasandhu.co/flourish-the-mastermind"
          target="_blank"
          className="border-[1px] cursor-pointer relative flex justify-center items-center h-96 p-4 hover:bg-gray-200 transition-colors duration-200 ease-in-out "
        >
          <img
            src={Sales}
            className="opacity-70 h-full w-full object-cover object-center"
          />
          <div className="hidden lg:block absolute w-[80%] text-center health">
            <h1
              className="text-[30px] font-[700] text-[#303030]"
              data-text="Sales Page Copy"
            ></h1>
          </div>
        </a>
      </section>
    </div>
  );
};

export default WebCopy;
