import React from "react";
import Retirement from 'assets/images/retirement.png'
import Budget from 'assets/images/Budget.png'
import Covid from 'assets/images/COVID.png'
import Biden from 'assets/images/Biden.png'
import Rental from 'assets/images/Rental.png'
import Friendship from 'assets/images/friendships.png'
import YoutubeEmbed from "components/YoutubeEmbed";

const Testimonials = () => {
    return (
        <div className="mt-[16px] lg:mt-[60px] w-[80%] lg:w-full mx-auto relative">
            <section className="py-[100px]">
                <div className="text-center">
                    <h1 className="mx-auto block text-[30px] lg:text-[40px] font-[700] my-7 text-[rgb(45,56,88)]">Testimonials</h1>
                </div>
                <div className="w-[90%] lg:w-[62%] flex flex-col mx-auto ">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-[20px]">
                        <YoutubeEmbed embedId="KM_-wziFq0c" />
                        <YoutubeEmbed embedId="WojLB6c8weo" />
                        <YoutubeEmbed embedId="nrCPAreWSHM" />
                        <YoutubeEmbed embedId="C2cebpOyc_s" />
                        <YoutubeEmbed embedId="4LRO0r7Lwro" />
                        <YoutubeEmbed embedId="cDxHfAqL57Q" />
                    </div>
                    <div className="flex justify-end mt-[20px]">
                        <a href="https://www.storybydhara.com/testimonial" target="_blank" className="bg-[#00bcd4] hover:cursor-pointer text-white px-[24px] py-[15px] rounded-[4px] hover:bg-[#008fa1] font-[500] text-[17px]" style={{ boxShadow: 'inset 0 -4px 0 0 rgba(0,0,0,.2)', transition: 'all .3s ease-in-out', border: "1px solid rgba(0,0,0,.2)" }}><span>More.. <i className="fa fa-arrow-right"></i></span></a>
                    </div>
                </div>
            </section>

            <section className="review-section">
                <div className="max-w-[1140px] py-[114px] px-[11.4px] mx-auto">
                    <div className="text-center">
                        <h1 className="text-[28px] lg:text-[40px] font-[700] text-[rgb(45,56,88)]">What Our <span style={{ color: 'rgba(3, 163, 236, 1)' }}>Clients</span> Say</h1>
                        <p className="text-[20px] lg:text-[25px] mt-[30px]" style={{ color: 'rgba(42, 95, 125, 0.73)' }}>Deepika Sandhu, an International Speaker and Life Expansion Mentor doubled her click through and open rates!</p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-5 relative mt-[80px]">
                        <div className="review-box p-[11.4px]">
                            <div className="flex flex-col items-center gap-5">
                                <div className="text-center p-[1vw]">
                                    <p className="text-[rgb(99,117,135)] text-[19px] lg:text-[1.25rem] leading-[1.375]">
                                        "She helped me get better at storytelling. I noticed that being able to story tell and give context is huge in finding your niche. We have worked together for three or four months developing a course. She has a way of slowing down."
                                    </p>
                                </div>
                                <div className="w-full">
                                    <img src='https://lh3.googleusercontent.com/nyif1aNQf5kzO3w6x3leUe4Gaf5wLJVsvCD0Wk5N71i66AQXs9SGn3LU25BLHa5eRKGQ9UxxNinLnI8gqF_ot3k-JFEv2ATA_w=w64' className="mx-auto" />
                                </div>
                                <div className="text-center p-[1vw]">
                                    <h2 className="font-[700] text-[18px]" style={{ color: 'rgba(0, 151, 220, 1)' }}>
                                        Jackie Richards
                                    </h2>
                                    <p className="text-[15px]" style={{ color: 'rgb(99,117,135)' }}>
                                        Social Media Personality (100K Followers) <br />
                                        Social Selling Top Leader <br />
                                        7 Figure Entreprenuer <br />
                                        <span className="font-[700]">Brand Storytelling Client</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="review-box p-[11.4px]">
                            <div className="flex flex-col items-center gap-5">
                                <div className="text-center p-[1vw]">
                                    <p className="text-[rgb(99,117,135)] text-[19px] lg:text-[1.25rem] leading-[1.375]">
                                        "Before I met Dhara, I was talking to buyers, and their eyes would glaze over with the scripts I've written for myself. Dhara asked me the right questions about my brand without changing the core of my story. I was so impressed. I don't know how she understood me so well in such a short time. My sales doubled at my next trade show using her words. "
                                    </p>
                                </div>
                                <div className="w-full">
                                    <img src='https://lh3.googleusercontent.com/bA4GrqQDnwJCvhcnvxJsTyixHjaLOx0_hT-zaJo153xK-vS6vxskOSA-QJ6o8igtKjIc8RxAjic0e8s9rMJvbfhrSx82pRL5aSbN=w64' className="mx-auto" />
                                </div>
                                <div className="text-center p-[1vw]">
                                    <h2 className="font-[700] text-[18px]" style={{ color: 'rgba(0, 151, 220, 1)' }}>
                                        Jaskirt
                                    </h2>
                                    <p className="text-[15px]" style={{ color: 'rgb(99,117,135)' }}>
                                        CEO, Jaskirt Mandahar Silks <br />
                                        <span className="font-[700]">Website, Brand Story and Social Media</span> <br />
                                        <span className="font-[700]">Copywriting Client</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="review-box p-[11.4px]">
                            <div className="flex flex-col items-center gap-5">
                                <div className="text-center p-[1vw]">
                                    <p className="text-[rgb(99,117,135)] text-[19px] lg:text-[1.25rem] leading-[1.375]">
                                        "Dhara is incredible at finding ways to change a simple piece of text into a story that captivates it's reader. She finds creative ways to spice up an essay and takes great pride in making sure everything is to the persons liking. I would highly recommend Dhara when it comes to essay/story counseling and guidance."
                                    </p>
                                </div>
                                <div className="w-full">
                                    <img src='https://lh3.googleusercontent.com/zpfh7bXJQVrnIWT54Ck-a9LP7bLc_oPhUSSyHkiygCjPYnK8qZjhKCp_geu-Wi6ut1Ie62yf-vzgSK2xdrrxR-sd7UHS81pHVg=w64' className="mx-auto" />
                                </div>
                                <div className="text-center p-[1vw]">
                                    <h2 className="font-[700] text-[18px]" style={{ color: 'rgba(0, 151, 220, 1)' }}>
                                        Alec Cheff
                                    </h2>
                                    <p className="text-[15px]" style={{ color: 'rgb(99,117,135)' }}>
                                        Student at University of Washington <br />
                                        <span className="font-[700]">(Admitted After Essay Counseling /Editing Session)</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end mt-[20px]">
                        <a href="https://www.discoveryournichecourse.com/copywritingservices/" target="_blank" className="bg-[#00bcd4] hover:cursor-pointer text-white px-[24px] py-[15px] rounded-[4px] hover:bg-[#008fa1] font-[500] text-[17px]" style={{ boxShadow: 'inset 0 -4px 0 0 rgba(0,0,0,.2)', transition: 'all .3s ease-in-out', border: "1px solid rgba(0,0,0,.2)" }}><span>More.. <i className="fa fa-arrow-right"></i></span></a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Testimonials