import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "assets/images/logo.svg";

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <header className="relative z-50">
      <div className="relative lg:fixed top-0 bg-[rgba(0,0,0,0.79)] h-[80px] lg:h-[60px] leading-[23px] w-full shadow-header">
        <div className="w-[80%] lg:w-full h-full px-0 lg:px-[30px] flex justify-between items-center mx-auto">
          <Link
            to="/"
            onClick={() => setMobileMenu(false)}
            className="header-transition ml-[-30px]"
          >
            <img
              src={Logo}
              className="w-[175px] lg:w-[160px] header-transition"
            />
          </Link>
          <div className="hidden lg:block header-transition">
            <ul className="flex space-x-[25px] uppercase text-[14px] text-white font-[600] leading-[1em]">
              <a href="mailto: info@storybydhara.com">
                <i className="fa fa-envelope"></i>
              </a>
              <a href="https://linkedin.com/in/dharasingh/" target="_blank">
                <i className="fa fa-linkedin"></i>
              </a>
              <a href="https://twitter.com/dsinghx" target="_blank">
                <i className="fa fa-twitter"></i>
              </a>
              <a href="http://instagram.com/itsdharasingh" target="_blank">
                <i className="fa fa-instagram"></i>
              </a>
              <Link to="/about">About me</Link>
              <a
                href="https://drive.google.com/uc?export=download&id=1BHBVOlUDJ-8o5kdnhscmg6KSPm5HhxKy"
                target="_blank"
              >
                Resume (CV)
              </a>
              <Link to="/testimonials">Testimonials</Link>
              <Link to="/webcopy"> B2C/B2B Creative Copywriting</Link>
              <Link to="/airclips">On Air Clips</Link>
              <Link to="/financial">Financial Writing</Link>
              <Link to="/technology">Technology Writing</Link>
            </ul>
          </div>
          <button
            onClick={() => setMobileMenu(!mobileMenu)}
            className="block lg:hidden"
          >
            {mobileMenu ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6 header-transition"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={3}
                color="#fff"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 header-transition"
                fill="none"
                stroke="currentColor"
                strokeWidth={3}
                color="#fff"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>
        <nav
          className={`flex flex-col gap-7 uppercase text-[14px] text-white font-[600] leading-[1em] absolute top-full left-0 w-full bg-[#121212] border-none opacity-70 border-b-[1px] border-white justify-center z-[11111] items-center transition-[height] duration-500 ease-out overflow-hidden ${
            mobileMenu ? "h-[500px]" : "h-0"
          }`}
        >
          <a
            href="mailto: info@storybydhara.com"
            onClick={() => {
              setMobileMenu(false);
            }}
          >
            <i className="fa fa-envelope"></i>
          </a>
          <a
            href="https://linkedin.com/in/dharasingh/"
            target="_blank"
            onClick={() => {
              setMobileMenu(false);
            }}
          >
            <i className="fa fa-linkedin"></i>
          </a>
          <a href="https://twitter.com/dsinghx" target="_blank">
            <i className="fa fa-twitter"></i>
          </a>
          <a
            href="http://instagram.com/itsdharasingh"
            target="_blank"
            onClick={() => {
              setMobileMenu(false);
            }}
          >
            <i className="fa fa-instagram"></i>
          </a>
          <Link
            to="/about"
            onClick={() => {
              setMobileMenu(false);
            }}
          >
            About me
          </Link>
          <a
            href="https://drive.google.com/uc?export=download&id=1BHBVOlUDJ-8o5kdnhscmg6KSPm5HhxKy"
            target="_blank"
            onClick={() => {
              setMobileMenu(false);
            }}
          >
            Resume (CV)
          </a>
          <Link
            to="/testimonials"
            onClick={() => {
              setMobileMenu(false);
            }}
          >
            Testimonials
          </Link>
          <Link
            to="/webcopy"
            onClick={() => {
              setMobileMenu(false);
            }}
          >
            B2C/B2B CREATIVE COPYWRITING
          </Link>
          <Link
            to="/airclips"
            onClick={() => {
              setMobileMenu(false);
            }}
          >
            On Air Clips
          </Link>
          <Link
            to="/financial"
            onClick={() => {
              setMobileMenu(false);
            }}
          >
            Financial Writing
          </Link>
          <Link
            to="/technology"
            onClick={() => {
              setMobileMenu(false);
            }}
          >
            Technology Writing
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
