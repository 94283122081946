import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from 'components/Layout';
import Home from 'pages/Home';
import About from 'pages/About';
import Testimonials from 'pages/Testimonials';
import WebCopy from 'pages/WebCopy';
import AirClips from 'pages/AirClips';
import Financial from 'pages/Financial';
import Technology from 'pages/Technology';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/webcopy" element={<WebCopy />} />
          <Route path="/airclips" element={<AirClips />} />
          <Route path="/financial" element={<Financial />} />
          <Route path="/technology" element={<Technology />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
