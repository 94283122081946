import React from "react";

const Footer = () => {
	return (
		<footer className="bg-[rgba(0,0,0,0.79)] h-[81px] lg:h-[55.5px] mt-[70px]">
			<div className="flex max-w-[1358px] h-full justify-center items-center w-[80%] lg:w-full mx-auto gap-5">
				<a href="http://instagram.com/itsdharasingh" target="_blank" className="text-[14px] text-white font-[600] hidden lg:block"><i className="fa fa-instagram"></i></a>
				<a href="https://linkedin.com/in/dharasingh/" target="_blank" className="text-[14px] text-white font-[600] hidden lg:block"><i className="fa fa-linkedin"></i></a>
				<p className="text-white text-center">Want to Follow My Journey and Receive Free Writing/Sales Tips? Subscribe Below: <a href="https://view.flodesk.com/pages/63bb296737a7fb86b1f99024" target="_blank" className="font-[700] cursor-pointer">Subscribe Below:</a></p>
			</div>
		</footer>
	)
}

export default Footer