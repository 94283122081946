import React from "react";
import BackToSchool from 'assets/images/back-to-school.webp'
import Hype from 'assets/images/5G-hype.webp'
import Sprint from 'assets/images/sprint-store-logo.webp'

const Technology = () => {
    return (
        <div className="mt-[16px] lg:mt-[60px] w-[80%] lg:w-full mx-auto relative">
            <section className="py-[100px]">
                <div className="w-[90%] lg:w-[62%] grid grid-cols-1 lg:grid-cols-2 gap-[20px] mx-auto mt-[30px] lg:mt-0">
                    <a href="https://www.cnet.com/news/6-back-to-school-picks-from-an-actual-college-student/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src={BackToSchool} />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            6 back-to-school picks from an actual college student
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/5g-hype-isnt-going-to-close-the-digital-divide/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src={Hype} />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            5G hype won't close the digital divide
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/sprints-subscriber-losses-show-why-it-needs-t-mobile-lifeline/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src={Sprint} />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            Sprint's subscriber losses show why it needs T-Mobile lifeline
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/stubhub-now-lets-you-buy-tickets-using-apple-pay-in-your-mobile-browser/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/4634be88ad5414ef8eaac830965f65963607004f/hub/2019/06/09/eccac2df-ee54-4cf8-be0b-6e2e4f826031/iphone-xr-1.jpg?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            StubHub now lets you buy tickets using Apple Pay in your mobile browser
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/googles-new-emergency-feature-lets-you-call-911-without-speaking/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/2f68b9b8c887fd4e658f7eeb9180ba12d64a908e/hub/2019/08/01/e3aab50e-fec8-4e53-9b35-2b3e593f87bf/dark-mode-android.jpg?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            Google's new emergency feature lets you call 911 without speaking
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/birds-new-scooter-rolls-out-next-week-with-damage-sensors-bigger-battery/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/fbca678597d1ba53f4b11eb7a8a0252f5da545bb/hub/2019/08/01/a9de1da3-4645-497b-a4fa-262a0fa0b81a/bird-two.png?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            Bird's new scooter rolls out next week with damage sensors, bigger battery
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/apple-lags-samsung-huawei-in-global-smartphone-market-says-strategy-analytics/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/32ca7d9545d2763155435a5f1cc2af2c49c523ab/hub/2019/07/22/1ae06b58-b0c9-4292-9202-7cc9a6a2946e/iphone-xs-11.jpg?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            Apple trails Samsung, Huawei in global smartphone market, says Strategy Analytics
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/two-active-meteor-showers-are-coming-this-week/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/4dc650dd82c7327f3b77de5d3a319820f01182a3/hub/2019/03/18/e8a60f6d-c484-4fb0-a653-95f10211d051/gettyimages-639546999.jpg?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            Two active meteor showers are coming this week
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/ai-is-getting-more-in-touch-with-your-emotions/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/4aa4a9b87005ab459ba5405c5c028e1b1dd13067/hub/2019/06/14/29aca6ad-40ad-43f9-9514-e49b6339277a/adobe-fake-image.png?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            AI is getting more in touch with your emotions
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/youtube-videos-featuring-children-rank-in-highest-views-pew-study-says/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/3839102d93bef4430a299d3a20224c4572e032f8/hub/2018/04/06/dae6a77d-0320-4fcb-b3bf-3670e95d52d9/gettyimages-659826780.jpg?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            YouTube videos featuring children rank in highest views, Pew study says
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/pete-buttigieg-calls-out-google-uber-in-new-economic-policy/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/3b5b94d820ea00b7557afcd2664f5cad89489196/hub/2019/07/26/b8d9bc94-ab09-4d83-9bba-2f7f9bfb8c70/gettyimages-1157600142.jpg?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            Pete Buttigieg calls out Google, Uber in new economic policy
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/southwest-airlines-leaving-newark-airport-due-to-boeing-737-woes/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/acdd53fbfb7af211c650a38abdb642ae63dc18a8/hub/2019/04/30/516e6b7c-91de-4c55-a5f2-774692ae5936/boeing-737-max-all-versions.jpg?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            Southwest Airlines leaving Newark Airport due to Boeing 737 woes
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/t-mobile-sprint-key-stats-before-the-merger/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/0c80183be6f4cbc1c9c9439d64321e00aae250f6/hub/2019/07/26/d95950f5-94bf-46b0-97c3-1c9258547da2/gettyimages-1135373965.jpg?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            T-Mobile-Sprint: Key stats before the merger
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/microsoft-removes-cortana-from-xbox-one/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/ca99b0f1f08c54491ba45e92dd21f365411cedae/hub/2019/07/25/df652a32-4077-4403-94be-238bdbdc47c2/consolehomeexperiment-hero.jpg?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            Microsoft cuts the cord on Cortana for Xbox One
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/apple-ios-update-targets-that-old-iphone-youve-got-lying-around/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/c64114988d88efcc37bed8cb7571f1fc743c4e34/hub/2018/12/08/3c61303f-33d4-40d5-9fd9-6cd184434f4a/iphone-4s-1.jpg?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            Apple iOS update targets that old iPhone you've got lying around
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/microsoft-invests-1-billion-into-openai-as-part-of-multi-year-deal/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/ebbe8bdae7a4a652e072a5ecd122ab3da9c04348/hub/2019/07/22/7bf311ea-0112-4e40-a805-0688397c7aaa/msft-nadella-openai-altman-09-official-joint-pic-1024x652.jpg?auto=webp&fit=crop&height=63&width=84    ' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            Microsoft invests $1 billion in OpenAI for supercomputing tech
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/new-youtube-music-feature-allows-users-to-switch-to-the-music-video/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/cb816d73e4492a14f661927be0834bffcfa41c2f/hub/2019/07/17/dea33a0b-6726-4c89-86e6-852a9974f421/gettyimages-659826780.jpg?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            YouTube Music will now let you roll between tracks and video
                        </p>
                    </a>
                    <a href="https://www.cnet.com/videos/netflix-probably-wont-be-jacking-up-prices-again-anytime-soon-the-daily-charge-7182019/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/aeb89255fea8e415cd9046663e05b49c8dbe7725/hub/2019/07/18/93bdce4a-0278-4e91-b424-5751b4511910/dcthumb07182019.jpg?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            Netflix probably won't be jacking up prices again anytime soon (The Daily Charge, 7/18/2019)
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/neopets-friendships-still-thrive-20-years-later/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/49b74b8b7b1732fc31be678dc7012cfdf0bb8752/hub/2019/07/18/cf1ad178-5916-456d-84e7-c0227b952dab/gettyimages-51188848.jpg?auto=webp&fit=crop&height=63&precrop=4208,2912,x277,y0&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            Neopets friendships still thrive 20 years later
                        </p>
                    </a>
                    <a href="https://www.cnet.com/news/google-facebook-and-twitter-sent-letters-about-deepfakes-by-rep-schiff/" target="_blank" className="relative flex justify-center items-center cursor-pointer gap-5">
                        <div className="relative">
                            <img src='https://www.cnet.com/a/img/resize/5d0821d8ed59dbde7bc6df7b69f34d04eba31f29/hub/2019/06/11/96c6f83a-4ccb-4408-ba8d-085d74bdbe7e/screen-shot-2019-06-11-at-3-14-25-pm.png?auto=webp&fit=crop&height=63&width=84' />
                        </div>
                        <p className="text-[12px] lg:text-[18px] font-[700] uppercase text-left w-[80%] text-[rgba(0,0,0,0.79)] hover:text-red-600">
                            Google, Facebook, Twitter put on notice about deepfakes in 2020 election
                        </p>
                    </a>

                </div>
            </section>
        </div>
    )
}

export default Technology