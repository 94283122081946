import React from "react";
import YoutubeEmbed from "components/YoutubeEmbed";

const AirClips = () => {
    return (
        <div className="mt-[16px] lg:mt-[60px] w-[80%] lg:w-full mx-auto relative">
            <section className="py-[100px]">
                <div className="w-[90%] lg:w-[62%] flex flex-col mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-[20px]">
                        <YoutubeEmbed embedId="o6tCkw2R70k" />
                        <YoutubeEmbed embedId="834jDwa1Jvc" />
                        <YoutubeEmbed embedId="QTCwY6fbNew" />
                    </div>
                    <div>
                        <h1 className="text-[#303030] text-[24px] lg:text-[40px] text-center font-[700] my-10">NJ.Com’s 2022 Cocktails and Connections Women Networking Event</h1>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
                        <div>
                            <img src="https://images.squarespace-cdn.com/content/v1/5fb3d50204bfbb239d59fa79/0d0818fa-17f0-4bd1-8496-86b2fb35582a/Screen+Shot+2022-12-18+at+1.29.51+AM.png?format=2500w" />
                        </div>
                        <div>
                            <img src="https://images.squarespace-cdn.com/content/v1/5fb3d50204bfbb239d59fa79/62b5dff1-ba07-4596-a646-70a87819d800/nj.com+dhara+singh+2.jpeg?format=2500w" />
                        </div>
                        <div>
                            <img src="https://images.squarespace-cdn.com/content/v1/5fb3d50204bfbb239d59fa79/c5e2bc30-6ab8-4e90-b29c-3b88f152f5af/nj.com+dhara+singh.jpeg?format=2500w" />
                        </div>
                    </div>
                    <div>
                        <h1 className="text-[#303030] text-[24px] lg:text-[40px] text-center font-[700] my-10">2-Hour NYC Personal Branding and Storytelling Workshop 12/7 </h1>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
                        <div>
                            <img src="https://images.squarespace-cdn.com/content/v1/5fb3d50204bfbb239d59fa79/18c7b6b3-fad4-48c1-b456-128453878809/conference.jpg?format=2500w" />
                        </div>
                        <div>
                            <img src="https://images.squarespace-cdn.com/content/v1/5fb3d50204bfbb239d59fa79/f6962673-b7fd-4ce9-b27a-c8eda88d0325/workbook+1.jpeg?format=1000w" />
                        </div>
                        <div>
                            <img src="https://images.squarespace-cdn.com/content/v1/5fb3d50204bfbb239d59fa79/769eeed2-d262-4dcc-b8c6-f2ccce7e3223/Group+Photo+.jpg?format=2500w" />
                        </div>
                    </div>
                    <div>
                        <h1 className="text-[#303030] text-[24px] lg:text-[40px] text-center font-[700] my-10">The Global 2022 Women Thrive Summit</h1>
                    </div>
                    <div className="grid grid-cols-1">
                        <div>
                            <img src="https://images.squarespace-cdn.com/content/v1/5fb3d50204bfbb239d59fa79/fcaa6663-4220-44e3-b1eb-bd474802ef3c/Screen+Shot+2022-05-20+at+3.15.25+PM.png?format=2500w" />
                        </div>
                    </div>
                    <div>
                        <h1 className="text-[#303030] text-[24px] lg:text-[40px] text-center font-[700] my-10">UN AFFILIATED Humanitarian Focus Foundation</h1>
                    </div>
                    <div className="grid grid-cols-1">
                        <div>
                            <img src="https://images.squarespace-cdn.com/content/v1/5fb3d50204bfbb239d59fa79/c85369d0-3cb6-4fb6-aa8b-42bc79c0fb3c/Screen+Shot+2022-05-20+at+3.22.34+PM.png?format=2500w" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AirClips